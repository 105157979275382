import _ from 'lodash';

import {
  HasSubsWithSubType,
  isAcademicSubscription,
  Privileges,
  SubscriptionForOrg,
} from './accounts/pricing';

// this file includes utility functions
// for storage & artifact tracking (aka reference)

export const ARTIFACT_TRACKING_LIMIT_GB = undefined;

export const bytesInKB = 1000;
export const bytesInMB = bytesInKB * 1000;
export const bytesInGB = bytesInMB * 1000;
export const bytesInTB = bytesInGB * 1000;
export const bytesInPB = bytesInTB * 1000;

export function getFreeStorageInGB<T extends HasSubsWithSubType>(
  primarySub: SubscriptionForOrg<T>,
  privileges: Privileges
) {
  if (primarySub && isAcademicSubscription(primarySub)) {
    return privileges.storageLimitGB;
  }
  return freePersonalStorageInGB;
}

export const freePersonalStorageInGB = 100;
export const freeAcademicStorageInGB = 200;
export const freeWeaveIngestionInMB = 100;

export const additionalStoragePricePerGB = 0.03;
export const storagePrices = [0.0, additionalStoragePricePerGB];
export const artifactPrices = [0.0, 0.0];

export const artifactFeatures = [`Free`];

export function getTotalStorageBytes(
  entities?: Array<{storageBytes: number}>
): number | undefined {
  if (entities == null) {
    return undefined;
  }

  return _.sumBy(entities, 'storageBytes');
}

export function getTotalArtifactTrackingBytes(
  entities?: Array<{referenceBytes: number}>
): number | undefined {
  if (entities == null) {
    return undefined;
  }

  return _.sumBy(entities, 'referenceBytes');
}

export const TRACKED_HOURS = [
  `Tier 1 up to 5,000 tracked hours`,
  `Tier 2 up to 10,000 tracked hours`,
  `Tier 3 up to 15,000 tracked hours`,
  `Over 15,000 hours, contact us`,
];

export const STORAGE_PRICE_PER_GB_TIER_1 = 0.03;

function calculateByteTier1<T extends HasSubsWithSubType>(
  primarySub: SubscriptionForOrg<T>,
  privileges: Privileges
): number {
  return getFreeStorageInGB(primarySub, privileges) * bytesInGB;
}

// TODO: move this calculation to backend
export function getEstimatedStorageCharge(
  storageByte: number,
  primarySub: SubscriptionForOrg<HasSubsWithSubType> | null | undefined,
  privileges: Privileges,
  customLimit?: number
): number {
  if (primarySub == null) {
    return 0;
  }
  const defaultLimit = calculateByteTier1(primarySub, privileges);
  const limit = customLimit ?? defaultLimit;
  let totalEstimate = 0;
  if (storageByte > limit) {
    totalEstimate +=
      STORAGE_PRICE_PER_GB_TIER_1 * ((storageByte - limit) / bytesInGB);
  }

  return totalEstimate;
}

// TODO: move this calculation to backend
export function getEstimatedArtifactReferenceCharge(
  referenceByte: number
): number {
  return 0;
}
